import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

import 'src/assets/base.scss';

import { ScrollToTop } from 'src/utils';
import AuthProvider from 'src/components/AuthProvider';
import ErrorBoundary from 'src/components/ErrorBoundary';
import ThemeConfigProvider from 'src/components/ThemeConfigProvider';
import ConfirmProvider from 'src/components/ConfirmProvider';

import Routes from './routes';
import MuiTheme from './theme';

const App = () => (
  <BrowserRouter basename="/">
    <AuthProvider>
      <CssBaseline />
      <ThemeProvider theme={MuiTheme}>
        <ThemeConfigProvider>
          <ConfirmProvider>
            <SnackbarProvider maxSnack={1}>
              <ScrollToTop>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </ScrollToTop>
            </SnackbarProvider>
          </ConfirmProvider>
        </ThemeConfigProvider>
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
