import Resource from '../Resource';

class Admin extends Resource {
  static path = '/admin';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  addRole = Resource.method({
    method: 'POST',
    path: '/:id/role',
  });

  removeRole = Resource.method({
    method: 'DELETE',
    path: '/:id/role/:roleId',
  });

  updateRole = Resource.method({
    method: 'PATCH',
    path: '/:id/role/:roleId',
  });

  resendInvite = Resource.method({
    method: 'POST',
    path: '/:id/invitation/resend',
  });
}

export default Admin;
