import React, { Fragment, useContext } from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';

import ThemeConfigContext from 'src/components/ThemeConfigProvider/context';
import StatuspageWidget from 'src/components/StatuspageWidget';

const Footer = (props) => {
  const { footerShadow, sidebarToggle, footerFixed } = useContext(
    ThemeConfigContext,
  );
  return (
    <Fragment>
      <Paper
        square
        elevation={footerShadow ? 11 : 2}
        className={clsx('app-footer text-black-50', {
          'app-footer--fixed': footerFixed,
          'app-footer--fixed__collapsed': sidebarToggle,
        })}
      >
        <div className="app-footer--inner">
          <div className="app-footer--second">
            <div className="d-flex flex-gap-10">
              <div>
                Copyright © {new Date().getFullYear()} &nbsp;
                <a href="https://rmsincoroprated.com" title="RMS Inc">
                  Lighthouse Management Services, Inc.
                </a>
              </div>
              <div>|</div>
              <Typography variant="caption">
                Version: {window.app.version}
              </Typography>
              <div>|</div>

              <StatuspageWidget />
            </div>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

export default Footer;
