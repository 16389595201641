import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import {
  TextField,
  InputAdornment,
  FormControl,
  Typography,
} from '@material-ui/core';

import { AccountBoxTwoTone, LockTwoTone } from '@material-ui/icons';
import PasswordField from 'src/components/PasswordField';
import LoadingButton from 'src/components/LoadingButton';

import useAuth from 'src/hooks/useAuth';
import useSnackbar from 'src/hooks/useSnackbar';

const LoginSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const LoginForm = ({ onEmailRequirement, className }) => {
  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(LoginSchema),
  });
  const { login } = useAuth();
  const { showSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    try {
      await login(data);
    } catch ({ message, response }) {
      if (
        response?.status === 422 &&
        message === 'REQUIRE_EMAIL' &&
        onEmailRequirement
      ) {
        return onEmailRequirement(data);
      }
      return showSnackbar({ message, variant: 'error' });
    }
  };

  return (
    <form
      className={`${className} px-md-5`}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className="mb-4">
        <FormControl className="w-100">
          <TextField
            fullWidth
            id="input-with-icon-adornment"
            label="Username/Email Address"
            name="username"
            type="text"
            error={!!errors.username}
            helperText={errors?.username?.message}
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBoxTwoTone />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <div className="mb-4">
        <FormControl className="w-100">
          <PasswordField
            id="standard-adornment-password"
            fullWidth
            label="Password"
            name="password"
            error={!!errors.password}
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoTone />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <div className="text-center">
        <LoadingButton
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          className="my-2"
          loading={formState.isSubmitting}
        >
          Sign in
        </LoadingButton>
      </div>
      <div className="text-center mt-2">
        <Link to="/password/recovery">
          <Typography variant="body2">Forgot password?</Typography>
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
