import Resource from '../Resource';

class User extends Resource {
  static path = '/user';
  static include = [Resource.CREATE, Resource.LIST];

  get = Resource.method({
    method: 'GET',
    path: '/me',
  });

  update = Resource.method({
    method: 'PATCH',
    path: '/me',
  });

  role = Resource.method({
    method: 'GET',
    path: '/me/role',
  });

  delete = Resource.method({
    method: 'DELETE',
    path: '/:id',
  });

  updateRole = Resource.method({
    method: 'PATCH',
    path: '/role',
  });

  updateEmail = Resource.method({
    method: 'PATCH',
    path: '/me/email',
  });
}

export default User;
