import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import PrinterSetup from './PrinterSetup';

const PrinterSetupNotice = ({ children }) => {
  const [showPrinterSetup, setShowPrinterSetup] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const preferences = JSON.parse(
      window.localStorage.getItem('printing-preferences'),
    );
    if (!preferences || preferences?.status === 'skipped') {
      setVisible(true);
    }
  }, [setVisible]);

  useEffect(() => {
    const hanldePreferenceChange = ({ detail }) => {
      setVisible(!(detail?.status === 'ok'));
    };
    window.addEventListener(
      'recalls-printer-configuration-closed',
      hanldePreferenceChange,
      false,
    );

    return () =>
      window.removeEventListener(
        'recalls-printer-configuration-closed',
        hanldePreferenceChange,
      );
  }, [setVisible]);

  const handleClick = () => setShowPrinterSetup(true);

  const handleClose = () => setShowPrinterSetup(false);

  if (!isVisible) return null;

  return (
    <>
      <MuiAlert
        className="mb-3"
        severity="warning"
        icon={false}
        action={
          <Button variant="outlined" color="primary" onClick={handleClick}>
            Configure
          </Button>
        }
      >
        {children ||
          'Configuring printers will help you to  setup different printers and print documents with a single click.'}
      </MuiAlert>

      {showPrinterSetup && <PrinterSetup onClose={handleClose} />}
    </>
  );
};

export default PrinterSetupNotice;
