import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardArrowDown as ArrowDownIcon } from '@material-ui/icons';

import {
  Avatar,
  Box,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import useAuth from 'src/hooks/useAuth';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

export default function HeaderUserbox() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    user: { _id: userId, isSuper, fullName, avatarUrl, roles },
    role,
    logout,
    assumeRoleByRoleId,
    assumePseudoRole,
  } = useAuth();

  const handleRoleSwitch = (roleId) => () => {
    if (role?.isPseudoRole) {
      assumePseudoRole(userId, { roleId });
    } else {
      assumeRoleByRoleId(roleId);
    }

    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center"
      >
        <Box>
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <Avatar sizes="44" alt={fullName} src={avatarUrl} />
          </StyledBadge>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">{fullName}</div>
          <span className="text-white-50 text-capitalize">{role?.name}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <ArrowDownIcon />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClose}
        className="ml-2"
      >
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pb-0">
            <Box>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar sizes="44" alt={fullName} src={avatarUrl} />
              </StyledBadge>
            </Box>
            <div>
              <p className="mb-1 font-weight-bold text-center pt-2 line-height-1">
                {fullName}
              </p>
              <p className="px-2 mb-0 text-black-50 text-wrap text-center text-capitalize">
                {role?.resource?.name}{' '}
                {role?.resource?.number ? `(#${role?.resource?.number})` : ''}
              </p>
              <p className="text-black-50 text-center text-capitalize">
                {role?.name}
              </p>
            </div>
            <Divider className="w-100" />

            {!isSuper && (
              <>
                {roles.length > 0 &&
                  roles
                    .filter(
                      (userRole) =>
                        userRole._id !== role?._id &&
                        userRole.resourceStatus !== 'Inactive',
                    )
                    .map((userRole) => (
                      <ListItem
                        key={userRole._id}
                        className="flex-column"
                        onClick={handleRoleSwitch(userRole._id)}
                        button
                      >
                        <p className="m-0 w-100 font-weight-bold text-wrap">
                          {userRole.resource.name}{' '}
                          {userRole?.resource?.number
                            ? `(#${userRole?.resource?.number})`
                            : ''}
                        </p>

                        <p className="m-0 w-100  text-black-50  text-capitalize">
                          {userRole.role.name}
                        </p>
                      </ListItem>
                    ))}
                <Divider className="w-100" />
              </>
            )}

            <ListItem className="mt-2 justify-content-center">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className="mr-2"
                component={Link}
                to="/account"
                onClick={handleClose}
              >
                My Account
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={logout}
              >
                Logout
              </Button>
            </ListItem>
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}
