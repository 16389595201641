import React, { Fragment, useContext } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box, Tooltip } from '@material-ui/core';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

import ThemeConfigContext from 'src/components/ThemeConfigProvider/context';

const SidebarHeader = (props) => {
  const {
    setThemeOption,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarHover,
  } = useContext(ThemeConfigContext);

  const toggleSidebar = () => {
    setThemeOption('sidebarToggle', !sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setThemeOption('sidebarToggleMobile', !sidebarToggleMobile);
  };

  return (
    <Fragment>
      <div
        className={clsx('app-sidebar-header', {
          'app-sidebar-header-close': sidebarToggle && !sidebarHover,
        })}
      >
        <Box className="header-logo-wrapper" title="Recalls 123">
          <Link
            to="/dashboard"
            onClick={toggleSidebarMobile}
            className="header-logo-wrapper-link"
          >
            <Box className="header-logo-text">Recalls 123</Box>
          </Link>
        </Box>
        <Box
          className={clsx('app-sidebar-header-btn', {
            'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover,
          })}
        >
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton color="inherit" onClick={toggleSidebar} size="medium">
              {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <Tooltip title="Toggle Sidebar" placement="right">
            <IconButton
              color="inherit"
              onClick={toggleSidebarMobile}
              size="medium"
            >
              {sidebarToggleMobile ? (
                <MenuOpenRoundedIcon />
              ) : (
                <MenuRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </Fragment>
  );
};

export default SidebarHeader;
