import React, { Fragment } from 'react';

import { Grid, Container, Button } from '@material-ui/core';

import { Link } from 'react-router-dom';

import {
  ArrowForward as ArrowRightIcon,
  ArrowDownward as ArrowDownIcon,
  EmojiObjects as BulbIcon,
  Cloud as CloudIcon,
  AttachMoney as MoneyIcon,
} from '@material-ui/icons';

import hero1 from 'src/assets/images/hero-bg/hero-1.jpg';
import logo from 'src/assets/images/logo-w.png';

import Footer from './Footer';
import useAuth from 'src/hooks/useAuth';

const LandingPage = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Fragment>
      <div className="hero-wrapper bg-composed-wrapper bg-plum-plate min-vh-100">
        <div className="header-nav-wrapper header-nav-wrapper-lg w-100 navbar-dark">
          <Container className="d-flex" fixed>
            <div className="header-nav-logo align-items-center d-flex justify-content-start">
              <div className="nav-logo">
                <Link to="/" title="Recalls 123">
                  <i className="bg-transparent">
                    <img alt="Recalls 123" src={logo} />
                  </i>
                </Link>
              </div>
            </div>
            <div className="header-nav-actions">
              <span className="d-lg-block">
                <Button
                  component={Link}
                  to="/dashboard"
                  className="px-3"
                  color="primary"
                  variant="contained"
                >
                  {isAuthenticated ? 'My account' : 'Sign in'}
                </Button>
              </span>
            </div>
          </Container>
        </div>

        <div className="flex-grow-1 w-100 d-flex align-items-center pb-5">
          <div
            className="bg-composed-wrapper--image"
            style={{ backgroundImage: 'url(' + hero1 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-second opacity-5" />
          <div className="bg-composed-wrapper--content">
            <Container className="py-4" fixed>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={7}>
                  <div className="text-white mt-3">
                    <h1 className="display-3 mb-3 font-weight-bold">
                      The industry leader in product recalls.
                    </h1>
                    <p className="font-size-lg text-white-50">
                      Our platform makes it easy to notify consumers about
                      recalled products by allowing businesses to communicate
                      tailored messages to both affected and non-affected
                      customers through multiple channels including text, voice
                      calls and email.
                    </p>
                    <div className="d-none divider border-2 my-5 border-light opacity-2 rounded-circle w-25" />
                    <div className="d-none">
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        component={Link}
                        to="/sign-up"
                      >
                        <span className="btn-wrapper--label">Sign up</span>
                        <span className="btn-wrapper--icon">
                          <ArrowRightIcon />
                        </span>
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
            <Container className="bg-composed-wrapper--content " fixed>
              <Grid container spacing={4} className="py-5">
                <Grid item xs={12} md={4}>
                  <div className="feature-box px-3 text-white mb-4">
                    <div className="bg-strong-bliss text-center text-white font-size-xl d-50 rounded-circle">
                      <BulbIcon fontSize="large" />
                    </div>
                    <h3 className="font-size-lg font-weight-bold mt-4">
                      Intuitive
                    </h3>
                    <p className="text-white-50 mt-2">
                      When a recall occurs, businesses are able to import or
                      upload their data to Recalls 123, and are guided every
                      step of the way to ensure accuracy.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="feature-box px-3 text-white mb-3">
                    <div className="bg-plum-plate text-center text-white font-size-xl d-50 rounded-circle">
                      <CloudIcon />
                    </div>
                    <h3 className="font-size-lg font-weight-bold mt-4">
                      Cloud based
                    </h3>
                    <p className="text-white-50 mt-2">
                      Our platfrom runs in the cloud. This means information
                      regarding recalls can be uploaded and monitored, from any
                      location with an internet connnection.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="feature-box px-3 text-white mb-4">
                    <div className="bg-arielle-smile text-center text-white font-size-xl d-50 rounded-circle">
                      <MoneyIcon />
                    </div>
                    <h3 className="font-size-lg font-weight-bold mt-4">
                      Cost effective
                    </h3>
                    <p className="text-white-50 mt-2">
                      Recalls 123 is on-demand, which means no annual or monthly
                      billing. Businesses only pay when a recall is managed on
                      the platform.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div className="hero-indicator animated font-size-xxl text-white opacity-10 d-none">
          <ArrowDownIcon />
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default LandingPage;
