import Resource from '../Resource';

class Store extends Resource {
  static path = '/store';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  export = Resource.method({
    method: 'GET',
    path: 'report.xlsx',
    responseType: 'stream',
  });

  staff = Resource.method({
    method: 'GET',
    path: '/:id/staff',
  });

  count = Resource.method({
    method: 'GET',
    path: '/count',
  });

  delete = Resource.method({
    method: 'DELETE',
    path: '/:id',
  });

  archive = Resource.method({
    method: 'POST',
    path: '/:id/archive',
  });

  updateDivision = Resource.method({
    method: 'PATCH',
    path: '/division',
  });

  recall = Resource.method({
    method: 'GET',
    path: '/:id/recall/:recallId',
  });
}

export default Store;
