import React, { Fragment } from 'react';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { Grid, Button } from '@material-ui/core';

import svgImage3 from 'src/assets/images/illustrations/404.svg';

export default function Error404({ history }) {
  const handleBack = () => history.go(-1);
  return (
    <Fragment>
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <Button
            onClick={handleBack}
            size="large"
            color="primary"
            variant="outlined"
            className="btn-go-back"
          >
            <span className="btn-wrapper--icon">
              <ArrowBackIcon />
            </span>
            <span className="btn-wrapper--label">Back</span>
          </Button>
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 px-lg-0 mx-auto text-center text-black"
                      >
                        <img
                          src={svgImage3}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />

                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          The page you were looking for doesn't exist.
                        </h3>
                        <p className="mb-4">
                          You may have mistyped the address or the page may have
                          moved.
                        </p>
                      </Grid>
                    </div>
                    <div className="hero-footer py-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
