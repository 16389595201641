import React, { useState, useEffect, useCallback } from 'react';
import AuthContext from './context';
import { useHistory } from 'react-router-dom';
import api from 'src/services/api';
import SuspenseLoading from '../SuspenseLoading';

import useConfiguration from 'src/hooks/useConfiguration';

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);

  const history = useHistory();
  const { clearConfig } = useConfiguration();

  const [hasInitialized, setInitialized] = useState(false);

  const onAuthenticationSuccess = useCallback(async () => {
    const userRequest = api.user.get();
    const roleRequest = api.user.role();

    const [currentUser, role] = await Promise.all([userRequest, roleRequest]);

    setUser(currentUser);
    setRole(role);
    clearConfig();
  }, [setUser, clearConfig]);

  const onTokenChange = useCallback(
    async (token) => {
      api.setToken(token);
      return onAuthenticationSuccess();
    },
    [onAuthenticationSuccess],
  );

  const handleToken = useCallback(
    async (token) => {
      await onTokenChange(token);
      const newState = {
        token,
        isAuthenticated: true,
      };
      window.localStorage.setItem('token', token);
      setAuth(newState);
    },
    [setAuth, onTokenChange],
  );

  const login = async (payload) => {
    const { token } = await api.token.create(payload);
    if (token) {
      return handleToken(token);
    }
  };

  const logout = useCallback(() => {
    window.localStorage.removeItem('token');
    api.setToken(null);
    setAuth(null);
    setRole(null);
    setUser(null);
    history.push('/login');
  }, [setAuth, setRole, setUser, history]);

  const handleNewRole = async () => {
    const { token } = await api.token.switch();
    if (token) {
      return handleToken(token);
    }
  };

  const assumeRoleByRoleId = async (roleId) => {
    const { token } = await api.token.switch(roleId);
    if (token) {
      await handleToken(token);
      history.push('/dashboard');
    }
  };

  const assumeRoleByCompany = async (companyId, { redirect }) => {
    const { token } = await api.token.administer(companyId);
    if (token) {
      await handleToken(token);
      history.push(redirect || '/dashboard');
    }
  };

  const assumePseudoRole = async (staffId, payload = {}) => {
    const { token } = await api.token.impersonate(staffId, payload);
    if (token) {
      await handleToken(token);
      history.push('/dashboard');
    }
  };

  const retreatRole = async ({ isPseudoRole }) => {
    await handleNewRole();
    history.push('/dashboard');
  };

  useEffect(() => {
    const init = async () => {
      const token = window.localStorage.getItem('token');
      if (token) {
        try {
          await handleToken(token);
        } catch ({ message }) {
          // TODO: Show error
          logout();
        }
      }
      return setInitialized(true);
    };
    init();
  }, [handleToken, logout, setInitialized]);

  useEffect(() => {
    const handleUnauthorized = () => {
      if (auth && auth.isAuthenticated) logout();
    };

    api.on('unauthorized', handleUnauthorized);
    return () => {
      api.off('unauthorized', handleUnauthorized);
    };
  }, [auth, logout]);

  // FIXME: The sync functions below are temp for updates, they need to be dry
  // probably use useReducer
  const syncUser = (payload) => {
    setUser({ ...user, ...payload });
  };

  const syncScope = (payload) => {
    const newRole = { ...role };
    newRole.resource = { ...role.resource, ...payload };
    setRole(newRole);
  };
  if (!hasInitialized) return <SuspenseLoading noSubtitle />;

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        user,
        role,
        logout,
        login,
        syncUser,
        syncScope,
        handleNewRole,
        assumeRoleByRoleId,
        assumeRoleByCompany,
        assumePseudoRole,
        retreatRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
