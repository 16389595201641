import React, { Fragment, useMemo, useState, useContext } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import { ExitToApp as ExitIcon } from '@material-ui/icons';

import Can from 'src/components/Can';
import LoadingButton from 'src/components/LoadingButton';
import ThemeConfigContext from 'src/components/ThemeConfigProvider/context';
import useSnackbar from 'src/hooks/useSnackbar';

const SidebarBox = ({ auth }) => {
  const {
    sidebarToggle,
    sidebarHover,
    setThemeOption,
    sidebarToggleMobile,
  } = useContext(ThemeConfigContext);
  const [isRetreating, setRetreating] = useState(false);
  const { showSnackbar } = useSnackbar();

  const {
    role: { resource, isAssumingRole, isPseudoRole },
    retreatRole,
  } = auth;

  // FIXME: Just rough thing, definitely need to rewrite better
  const level = useMemo(() => {
    const level = {
      Company: 'company',
      Division: 'division',
      DivisionGroup: 'division-group',
      Store: 'store',
      StoreGroup: 'store-group',
    }[resource.type];
    const levelsWithoutUpdateRoute = ['DivisionGroup', 'StoreGroup'];

    const basePath =
      resource.type === 'Company' ? `/${level}` : `/${level}s/${resource._id}`;

    return {
      permission: `${level}:update`,
      viewRoute: resource.type === 'Company' ? '/dashboard' : basePath,
      updateRoute: !levelsWithoutUpdateRoute.includes(resource.type)
        ? `${basePath}/update`
        : null,
    };
  }, [resource]);

  const handleRetreat = () => {
    setRetreating(true);
    return retreatRole({ isPseudoRole })
      .catch((error) => {
        showSnackbar({ variant: 'error', message: error.message });
      })
      .finally(() => setRetreating(false));
  };

  const handleToggleMenu = () => {
    if (sidebarToggleMobile) setThemeOption('sidebarToggleMobile', false);
  };

  return (
    <Fragment>
      <Box
        className={clsx('app-sidebar-userbox', {
          'app-sidebar-userbox--collapsed': sidebarToggle && !sidebarHover,
        })}
      >
        {isAssumingRole && (
          <LoadingButton
            loading={isRetreating}
            className="w-100 mb-2 overflow-hidden"
            variant="outlined"
            onClick={handleRetreat}
          >
            <ExitIcon className="exit-icon rounded-0" />{' '}
            {isPseudoRole ? 'Exit Impersonation' : 'Back to Admin Menu'}
          </LoadingButton>
        )}

        {resource.avatarUrl && (
          <Link
            title={resource.name}
            onClick={handleToggleMenu}
            to={level.viewRoute}
          >
            <img
              alt={resource.name}
              src={resource.avatarUrl}
              variant="rounded"
              className="app-sidebar-userbox-avatar"
            />
          </Link>
        )}

        <Box className="app-sidebar-userbox-name">
          {(resource.type !== 'Company' || !resource.avatarUrl) && (
            <Box>
              <Link
                title={resource.name}
                onClick={handleToggleMenu}
                to={level.viewRoute}
              >
                <b className="text-wrap">
                  {resource.name}{' '}
                  {resource.number ? `(#${resource.number})` : ''}
                </b>
              </Link>
            </Box>
          )}
          <Box className="app-sidebar-userbox-description text-uppercase">
            <small>{resource.type}</small>
          </Box>
          {level.updateRoute && (
            <Can
              perform={level.permission}
              yes={() => (
                <Box className="app-sidebar-userbox-btn-profile">
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={handleToggleMenu}
                    component={Link}
                    to={level.updateRoute}
                  >
                    Settings
                  </Button>
                </Box>
              )}
            />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default SidebarBox;
