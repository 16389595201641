import { lazy } from 'react';

import LoginPage from 'src/pages/Login';
const PasswordRecoveryPage = lazy(() => import('src/pages/PasswordRecovery'));
const PasswordResetPage = lazy(() => import('src/pages/PasswordResetPage'));
const StaticPasswordRecoveryPage = lazy(() =>
  import('src/pages/StaticPasswordRecovery'),
);
const AcceptInvitationPage = lazy(() =>
  import('src/pages/AcceptInvitationPage'),
);
const AcknowledgeDiagnosticTest = lazy(() =>
  import('src/pages/AcknowledgeDiagnosticTest'),
);

const guestOnlyRoutes = {
  '/login': LoginPage,
  '/password/recovery': PasswordRecoveryPage,
  '/password/reset/:secret': PasswordResetPage,
  '/accept/:secret': AcceptInvitationPage,
  '/diagnostic-test/:id': AcknowledgeDiagnosticTest,
  '/password/recovery-without-email': StaticPasswordRecoveryPage,
};

export default guestOnlyRoutes;
