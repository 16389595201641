import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

const GuestOnlyRoute = ({ permission, ...routeProps }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    const path = localStorage.getItem('redirect') || '/dashboard';
    localStorage.removeItem('redirect');
    return <Redirect to={path} />;
  }

  return <Route {...routeProps} />;
};

export default GuestOnlyRoute;
