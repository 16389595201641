import React, { Fragment } from 'react';

import { Container } from '@material-ui/core';

import projectLogo from 'src/assets/images/logo.png';

export default function Footer() {
  return (
    <Fragment>
      <div className="py-5">
        <Container className="py-5">
          <div
            className="nav-logo bg-light rounded-sm mx-auto mx-xl-0 mb-2"
            title="Recalls 123"
          >
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="d-block"
              title="Recalls 123"
            >
              <i className="m-auto">
                <img alt="Recalls 123" src={projectLogo} />
              </i>
            </a>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <a
              href="mailto:support@recalls123.com"
              className="d-block"
              title="Contact"
            >
              Contact us
            </a>
          </div>
          <div className="divider border-1 d-none d-md-block rounded-circle border-dark bg-dark opacity-2 mx-auto my-5 w-25" />
          <div className="text-center d-block text-black-50">
            Copyright &copy; {new Date().getFullYear()} - Lighthouse Management
            Services, Inc. All Rights Reserved.
          </div>
        </Container>
      </div>
    </Fragment>
  );
}
