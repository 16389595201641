import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import {
  TextField,
  InputAdornment,
  FormControl,
  Typography,
} from '@material-ui/core';

import { AccountBoxTwoTone } from '@material-ui/icons';
import LoadingButton from 'src/components/LoadingButton';

import useSnackbar from 'src/hooks/useSnackbar';
import api from 'src/services/api';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .lowercase()
    .trim()
    .required(),
  emailConfirmation: yup
    .string()
    .email()
    .lowercase()
    .trim()
    .required()
    .oneOf([yup.ref('email')], 'Please enter the same email address'),
});

const CaptureEmail = ({ credentials }) => {
  const [completed, setCompleted] = useState(false);
  const { register, handleSubmit, errors, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
  });

  const { showSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    try {
      await api.staff.addEmailByCredentials({ ...credentials, ...data });
      setCompleted(true);
    } catch ({ message, response }) {
      return showSnackbar({ message, variant: 'error' });
    }
  };

  if (completed) {
    return (
      <div className="text-center">
        <Typography className="mb-3" color="secondary" variant="h4">
          Verification email sent!
        </Typography>
        <Typography variant="body1">
          We've emailed you instructions for activating the account.
          <br />
          <br />
          If you don't receive the email, please check your spam/junk folder.
        </Typography>
      </div>
    );
  }

  return (
    <form className="px-md-5" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="text-center">
        <Typography color="secondary" variant="h4">
          We need your email address
        </Typography>
        <Typography variant="caption" className="mt-2" component="p">
          Please enter your email address in the form below and we will mail you
          the account activation instructions.
        </Typography>
      </div>
      <div className="mb-4 mt-3">
        <FormControl className="w-100">
          <TextField
            fullWidth
            id="input-with-icon-adornment"
            label="Email Address"
            name="email"
            type="text"
            autoFocus
            error={!!errors.email}
            helperText={errors?.email?.message}
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBoxTwoTone />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <div className="mb-4 mt-3">
        <FormControl className="w-100">
          <TextField
            fullWidth
            id="input-with-icon-adornment"
            label="Confirm Email Address"
            name="emailConfirmation"
            type="text"
            error={!!errors.emailConfirmation}
            helperText={errors?.emailConfirmation?.message}
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBoxTwoTone />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>

      <div className="mb-2">
        <Typography variant="caption" className="text-warning">
          <strong>Important:</strong> This email address will be linked to your
          account on confirmation and can be used for password recovery.
        </Typography>
      </div>

      <div className="text-center">
        <LoadingButton
          color="primary"
          variant="contained"
          size="large"
          type="submit"
          className="my-2"
          loading={formState.isSubmitting}
        >
          Submit
        </LoadingButton>
      </div>
    </form>
  );
};

export default CaptureEmail;
