import Resource from '../Resource';

class Item extends Resource {
  static path = '/item';
  static include = [Resource.CREATE, Resource.UPDATE, Resource.LIST];

  get = Resource.method({
    method: 'GET',
    path: '/:upc',
  });

  categories = Resource.method({
    method: 'GET',
    path: '/category',
  });

  manufacturers = Resource.method({
    method: 'GET',
    path: '/manufacturer',
  });

  vendors = Resource.method({
    method: 'GET',
    path: '/vendor',
  });

  departments = Resource.method({
    method: 'GET',
    path: '/department',
  });
}

export default Item;
