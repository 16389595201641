import { IconButton } from '@material-ui/core';
import { CloseOutlined as CloseIcon } from '@material-ui/icons';

import { useSnackbar as useNotiSnackbar } from 'notistack';

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotiSnackbar();

  const handleClose = () => {
    closeSnackbar();
  };

  const showSnackbar = ({ variant, message, ...props }) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 5000,
      action: () => (
        <IconButton className="text-white" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      ),
      ...props,
    });
  };

  return { showSnackbar };
};

export default useSnackbar;
