import React, { Suspense, useMemo } from 'react';
import { Switch, Route, useLocation, matchPath } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { createBrowserHistory } from 'history';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import env from 'src/env';

import LayoutMinimal from 'src/components/LayoutMinimal';
import LayoutWithSidebar from 'src/components/LayoutWithSidebar';
import SuspenseLoading from 'src/components/SuspenseLoading';

import ProtectedRoute from 'src/components/ProtectedRoute';
import GuestOnlyRoute from 'src/components/GuestOnlyRoute';
import MotionDiv from 'src/components/MotionDiv';
import FeedbackWidget from 'src/components/FeedbackWidget';

import useAuth from 'src/hooks/useAuth';

import LandingPage from 'src/pages/LandingPage';
import EmailConfirmationPage from 'src/pages/EmailConfirmation';
import Error404 from 'src/pages/Error404';

import { routesByPermissions, allProtectedPaths } from './utils';
import guestOnlyRoutes from './guestOnly';

const commonRoutes = {
  '/': LandingPage,
  '/confirm/:secret': EmailConfirmationPage,
};

const history = createBrowserHistory();

const allCommonPaths = Object.keys(commonRoutes);
const allGuestPaths = Object.keys(guestOnlyRoutes);
const allRoutes = [
  ...allCommonPaths,
  ...allGuestPaths,
  ...allProtectedPaths,
].map((path) => ({
  path,
}));

const sentryDSN = env('SENTRY_DSN', false);
const environment = env('ENV', false);

Sentry.init({
  environment,
  dsn: sentryDSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        history,
        allRoutes,
        matchPath,
      ),
    }),
    new Sentry.Replay(),
  ],
  ignoreErrors: ['ChunkLoadError'],
  tracesSampleRate: environment === 'production' ? 0.8 : 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: !!sentryDSN,
  release: `${env('NAME')}@${env('VERSION')}`,
});

const Routes = () => {
  const location = useLocation();
  const auth = useAuth();
  const { user, role } = auth;

  const routes = useMemo(() => routesByPermissions(auth), [auth]);

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Route exact path={allCommonPaths}>
            <LayoutMinimal>
              <Switch location={location} key={location.pathname}>
                <MotionDiv>
                  {Object.entries(commonRoutes).map(([path, component]) => (
                    <Route
                      key={path}
                      path={path}
                      component={component}
                      exact={true}
                    />
                  ))}
                </MotionDiv>
              </Switch>
            </LayoutMinimal>
          </Route>

          <GuestOnlyRoute exact path={allGuestPaths}>
            <LayoutMinimal>
              <Switch location={location} key={location.pathname}>
                <MotionDiv>
                  {Object.entries(guestOnlyRoutes).map(([path, component]) => (
                    <GuestOnlyRoute
                      key={path}
                      path={path}
                      component={component}
                    />
                  ))}
                </MotionDiv>
              </Switch>
            </LayoutMinimal>
          </GuestOnlyRoute>

          <ProtectedRoute path={allProtectedPaths}>
            <LayoutWithSidebar>
              <Switch location={location} key={location.pathname}>
                {Object.entries(routes).map(
                  ([path, { component, permission, exact }]) => (
                    <ProtectedRoute
                      animate
                      key={path}
                      path={path}
                      component={component}
                      permission={permission}
                      exact={exact !== false ? true : false}
                    />
                  ),
                )}
              </Switch>
              {(role?.resource?.type !== 'Store' || role?.isAssumingRole) && (
                <FeedbackWidget user={user} />
              )}
            </LayoutWithSidebar>
          </ProtectedRoute>

          <Route path="*" component={Error404} status={404} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
