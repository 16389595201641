import { ConfirmProvider as BaseConfirmProvider } from 'material-ui-confirm';

const ConfirmProvider = ({ children }) => {
  return (
    <BaseConfirmProvider
      defaultOptions={{
        dialogProps: { className: 'custom-confirm-dialog' },
        confirmationButtonProps: {
          color: 'primary',
          variant: 'contained',
          autoFocus: true,
        },
        cancellationButtonProps: {
          color: 'default',
          variant: 'outlined',
        },
      }}
    >
      {children}
    </BaseConfirmProvider>
  );
};

export default ConfirmProvider;
