import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

const LayoutMinimal = (props) => {
  const { children } = props;

  return <Fragment>{children}</Fragment>;
};

LayoutMinimal.propTypes = {
  children: PropTypes.node,
};

export default LayoutMinimal;
