import React, { useState, useCallback } from 'react';
import ThemeConfigContext from './context';

import defaults from './defaults';

const ThemeConfigProvider = ({ children }) => {
  const [state, setState] = useState({ ...defaults });

  const setThemeOption = useCallback((key, value) => {
    setState((currentState) => ({ ...currentState, [key]: value }));
  }, []);

  return (
    <ThemeConfigContext.Provider
      value={{
        ...state,
        setThemeOption,
      }}
    >
      {children}
    </ThemeConfigContext.Provider>
  );
};

export default ThemeConfigProvider;
