import Resource from '../Resource';

class Configuration extends Resource {
  static path = '/configuration';
  static include = [Resource.LIST];

  get = Resource.method({
    method: 'GET',
    path: '/:key',
  });

  update = Resource.method({
    method: 'PATCH',
    path: '/:key',
  });

  createShippingAccount = Resource.method({
    method: 'POST',
    path: '/shipping-providers',
  });

  updateShippingAccount = Resource.method({
    method: 'PATCH',
    path: '/shipping-providers/:id',
  });

  listShippingAccounts = Resource.method({
    method: 'GET',
    path: '/shipping-providers',
  });

  qzCertificate = Resource.method({
    method: 'GET',
    path: '/qz/certificate',
  });

  qzSign = Resource.method({
    method: 'POST',
    path: '/qz/sign',
  });

  updatePeriod = Resource.method({
    method: 'PATCH',
    path: '/period/:key',
  });

  getPeriod = Resource.method({
    method: 'GET',
    path: '/period/:key',
  });

  periodPreview = Resource.method({
    method: 'POST',
    path: '/period/preview',
  });

  getCurrentPeriod = Resource.method({
    method: 'GET',
    path: '/period/current',
  });
}

export default Configuration;
