import Resource from '../Resource';

class Invitation extends Resource {
  static path = '/invitation';

  get = Resource.method({
    method: 'GET',
    path: '/:secret',
  });

  accept = Resource.method({
    method: 'POST',
    path: '/',
  });
}

export default Invitation;
