// FIXME: WTF...Why doesn't the local take priority over the global!
import EventEmitter from 'events';
import env from 'src/env';

import Token from './resources/Token';
import User from './resources/User';
import Verification from './resources/Verification';
import Password from './resources/Password';
import Company from './resources/Company';
import DivisionGroup from './resources/DivisionGroup';
import Division from './resources/Division';
import StoreGroup from './resources/StoreGroup';
import Store from './resources/Store';
import Staff from './resources/Staff';
import Recall from './resources/Recall';
import Item from './resources/Item';
import RecallResponse from './resources/RecallResponse';
import RecallTarget from './resources/RecallTarget';
import RecallResponseRevision from './resources/RecallResponseRevision';

import Admin from './resources/Admin';
import Role from './resources/Role';
import Invitation from './resources/Invitation';
import Configuration from './resources/Configuration';
import Integration from './resources/Integration';
import MessagingGroup from './resources/MessagingGroup';
import NotificationTemplate from './resources/NotificationTemplate';
import Message from './resources/Message';
import FileImport from './resources/FileImport';
import Automation from './resources/Automation';
import Activity from './resources/Activity';
import DiagnosticTest from './resources/DiagnosticTest';
import Bill from './resources/Bill';
import BillingPolicy from './resources/BillingPolicy';

const resources = {
  Token,
  User,
  Verification,
  Password,
  Company,
  Division,
  DivisionGroup,
  Store,
  StoreGroup,
  Staff,
  Recall,
  Item,
  RecallResponse,
  RecallTarget,
  Admin,
  Role,
  Invitation,
  Configuration,
  Integration,
  MessagingGroup,
  NotificationTemplate,
  Message,
  FileImport,
  Automation,
  Activity,
  RecallResponseRevision,
  DiagnosticTest,
  Bill,
  BillingPolicy,
};

const self = Symbol('self');

export class API {
  static DEFAULT_HOST = env('API_BASE_URL');
  static DEFAULT_PROTOCOL = 'http';
  static DEFAULT_BASE_PATH = env('API_VERSION');
  static DEFAULT_VERSION = env('API_VERSION');
  static DEFAULT_TIMEOUT = 30000;

  constructor() {
    this[self] = {
      host: API.DEFAULT_HOST,
      protocol: API.DEFAULT_PROTOCOL,
      basePath: API.DEFAULT_BASE_PATH,
      version: API.DEFAULT_VERSION,
      timeout: API.DEFAULT_TIMEOUT,
      token: null,
    };

    Object.defineProperty(this, 'emitter', {
      value: new EventEmitter(),
      enumerable: false,
      configurable: false,
      writeable: false,
    });

    this.on = this.emitter.on.bind(this.emitter);
    this.off = this.emitter.off.bind(this.emitter);

    Object.entries(resources).forEach(([name, Resource]) => {
      this[name[0].toLowerCase() + name.substring(1)] = new Resource(this);
    });
  }

  const(name) {
    return this.constructor[name];
  }

  set(key, value) {
    this[self][key] = value;
  }

  get(key) {
    return this[self][key];
  }

  setToken(value) {
    this[self]['token'] = value;
  }
}

const api = new API();

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  window.api = api;
}

export default api;
