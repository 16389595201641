import Resource from '../Resource';

class DiagnosticTest extends Resource {
  static path = '/diagnostic-test';
  static include = [Resource.CREATE];

  testVoice = Resource.method({
    method: 'POST',
    path: '/voice',
  });

  listEmailDeliveries = Resource.method({
    method: 'GET',
    path: '/email-delivery',
  });
}

export default DiagnosticTest;
