import streamSaver from 'streamsaver';

import { DateTime } from 'src/utils/luxon';

// FIXME: This won't work if the app is hosted in a subfolder.
streamSaver.mitm = `/streamsaver/mitm.html`;

const streamExporter = (
  request,
  { title },
  downloadInProgress = false,
) => async () => {
  const response = await request({ tz: DateTime.local().zoneName });

  if (!response.ok) {
    throw new Error(
      'Sorry! We are not able to process your export request at this time.',
    );
  }

  const preventNavigation = (event) => {
    if (downloadInProgress) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  window.removeEventListener('beforeunload', preventNavigation);
  window.addEventListener('beforeunload', preventNavigation);

  const attachment = response.headers.get('content-disposition');

  const fileName =
    attachment?.split('filename=')[1] || `${title || 'report'}.csv`;

  const fileStream = streamSaver.createWriteStream(
    fileName.replaceAll('"', ''),
  );

  const readableStream = response.body;

  downloadInProgress = true;
  // more optimized
  if (
    window.WritableStream &&
    readableStream.pipeTo &&
    !window.WebStreamsPolyfill
  ) {
    return readableStream.pipeTo(fileStream);
  }

  window.writer = fileStream.getWriter();

  const reader = response.body.getReader();
  const pump = () =>
    reader
      .read()
      .then((response) =>
        response.done
          ? window.writer.close()
          : window.writer.write(response.value).then(pump),
      )
      .catch((error) => {
        return;
      })
      .finally(() => {
        downloadInProgress = false;
      });

  pump();
};

export default streamExporter;
