import { useState, useEffect, useCallback } from 'react';
import { indexBy, prop } from 'ramda';

import api from 'src/services/api';
import { DateTime } from 'src/utils/luxon';

let configurations = null;
let lastConfigFetchAt;

const useConfiguration = ({ key = null } = {}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const checkAndfetchConfig = useCallback(async () => {
    if (
      lastConfigFetchAt &&
      DateTime.local()
        .diff(lastConfigFetchAt)
        .toMillis() <
        60000 * 15 // 15 mins
    )
      return;

    setLoading(true);
    setError(null);
    try {
      const result = await api.configuration.list();
      lastConfigFetchAt = DateTime.local();
      configurations = indexBy(prop('key'), result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setError]);

  const getConfig = useCallback(
    async (key, { refetch = false } = {}) => {
      if (refetch) {
        lastConfigFetchAt = null;
      }
      await checkAndfetchConfig();
      return configurations?.[key];
    },
    [checkAndfetchConfig],
  );

  const updateConfig = useCallback(async (key, value) => {
    const config = await api.configuration.update(key, value);
    configurations = { ...configurations, [key]: config };
    return config;
  }, []);

  useEffect(() => {
    if (key) {
      getConfig(key).then((config) => setValue(config?.value));
    }
  }, [key, getConfig]);

  const clearConfig = useCallback(() => {
    lastConfigFetchAt = null;
    configurations = null;
  }, []);

  return {
    getConfig,
    clearConfig,
    updateConfig,
    error,
    loading,
    value,
  };
};

export default useConfiguration;
