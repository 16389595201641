import {
  AccountTree as Divisions,
  SupervisorAccount as Users,
  Store as Stores,
  Business as Companies,
  SupervisorAccount as AdminUsers,
  Fingerprint as Roles,
  Replay as Recalls,
  Description as Reports,
  Settings as Configurations,
  GroupWork as MessagingGroups,
  GroupWorkOutlined as StoreGroups,
  GroupWorkRounded as DivisionGroup,
  Adb as Automation,
  Apps as Advanced,
  ViewList as ActivityLog,
  Build as Diagnostics,
  Book as KnowledgeCenter,
  Description as Bill,
  Settings,
} from '@material-ui/icons';

const navIcons = {
  Divisions,
  'Division Groups': DivisionGroup,
  Stores,
  'Store Groups': StoreGroups,
  Users,
  Companies,
  'Admin Users': AdminUsers,
  Roles,
  'My Recalls': Recalls,
  Reports,
  Configurations,
  Advanced,
  'Messaging Groups': MessagingGroups,
  Automation,
  'Activity Logs': ActivityLog,
  Diagnostics: Diagnostics,
  'Knowledge Center': KnowledgeCenter,
  Billing: Bill,
  'Billing Policy': Settings,
};

export default navIcons;
