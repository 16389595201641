import useAuth from 'src/hooks/useAuth';

const Can = ({ perform, yes, no }) => {
  const { role } = useAuth();

  if (role && role.permissions && role.permissions.includes(perform)) {
    return yes();
  }

  return no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
