import React, { Fragment, useContext } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Box } from '@material-ui/core';

import projectLogo from 'src/assets/images/logo-w.png';
import ThemeConfigContext from 'src/components/ThemeConfigProvider/context';

import env from 'src/env';
const environment = env('ENV', false);

const HeaderLogo = (props) => {
  const { sidebarToggle, sidebarHover } = useContext(ThemeConfigContext);
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover,
        })}
      >
        <Box className="header-logo-wrapper" title="Recalls 123 - Dashboard">
          <Link to="/dashboard" className="header-logo-wrapper-link">
            <i className="bg-transparent">
              <img
                width="179"
                height="32"
                className="app-header-logo-img"
                alt="Recalls 123"
                src={projectLogo}
              />
              {environment !== 'production' && (
                <span
                  className="text-light font-weight-bolder position-absolute"
                  style={{ left: '15px', top: '50px', fontStyle: 'normal' }}
                >
                  DEVELOPMENT SERVER{' '}
                  <span className="animate-pulse">&#x26A0;&#xFE0F;</span>
                </span>
              )}
            </i>
          </Link>
        </Box>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
