import Resource from '../Resource';

class Activity extends Resource {
  static path = '/activity';
  static include = [Resource.LIST];

  recentCompanies = Resource.method({
    method: 'GET',
    path: '/recent/company',
  });

  recentRecalls = Resource.method({
    method: 'GET',
    path: '/recent/recall',
  });

  statics = Resource.method({
    method: 'GET',
    path: '/statics',
  });
}

export default Activity;
