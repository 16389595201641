import protectedRoutes from './protected';
import navIcons from './navIcons';

const bestRoute = (routes, auth) => {
  return routes.find(
    (route) =>
      !route.permission ||
      (auth.role?.permissions.includes(route.permission) &&
        (!route.check || route.check(auth))),
  );
};

export const makeRoutesByPermissions = (masterRoutes) => (auth) =>
  Object.entries(masterRoutes).reduce((filteredRoutes, [key, route]) => {
    const value = bestRoute(Array.isArray(route) ? route : [route], auth);
    if (value) {
      filteredRoutes[key] = value;
    }
    return filteredRoutes;
  }, {});

export const makeNavByPermissions = (masterRoutes) => (auth) => {
  const navObject = Object.entries(masterRoutes)
    .sort(
      ([, a], [, b]) =>
        (a.menu?.priority || Infinity) - (b.menu?.priority || Infinity),
    )
    .reduce((filteredRoutes, [key, route]) => {
      const value = bestRoute(Array.isArray(route) ? route : [route], auth);

      if (value && value.menu) {
        const { menu } = value;
        if (menu.group) {
          if (!filteredRoutes[menu.group]) {
            filteredRoutes[menu.group] = {
              label: menu.group,
              content: [],
              icon: navIcons[menu.group],
            };
          }

          filteredRoutes[menu.group].content = [
            ...filteredRoutes[menu.group].content,
            { label: menu.label, to: key },
          ];
        } else {
          filteredRoutes[key] = {
            label: menu.label,
            icon: navIcons[menu.label],
            to: key,
          };
        }
      }
      return filteredRoutes;
    }, {});

  const content = Object.values(navObject);

  return [
    {
      label: 'Menu',
      content,
    },
  ];
};

export const navByPermissions = makeNavByPermissions(protectedRoutes);

export const routesByPermissions = makeRoutesByPermissions(protectedRoutes);

export const allProtectedPaths = Object.keys(protectedRoutes);
