import React, { useMemo, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useAuth from 'src/hooks/useAuth';

import { routesByPermissions } from 'src/routes';

import ThemeConfigContext from 'src/components/ThemeConfigProvider/context';

const useStyles = makeStyles({
  link: {
    '&:hover': {
      color: 'inherit !important',
    },
  },
});

const HeaderMenu = () => {
  const classes = useStyles();
  const auth = useAuth();
  const { user } = auth;

  const { setThemeOption, shortcuts } = useContext(ThemeConfigContext);

  // TODO: Need a cleaner approach.
  useEffect(() => {
    const defaults = {
      '/recalls/draft': { label: 'Add New Recall' },
      '/admin/new': { label: 'Add Admin' },
    };
    if (window.localStorage.getItem(`shortcuts_${user._id}`)) {
      setThemeOption(
        'shortcuts',
        JSON.parse(window.localStorage.getItem(`shortcuts_${user._id}`)),
      );
    } else {
      setThemeOption('shortcuts', defaults);
    }
  }, [setThemeOption, user._id]);

  useEffect(() => {
    window.localStorage.setItem(
      `shortcuts_${user._id}`,
      JSON.stringify(shortcuts),
    );
  }, [user._id, shortcuts]);

  const list = useMemo(() => {
    if (Object.keys(shortcuts).length === 0) return shortcuts;
    const routes = routesByPermissions(auth);

    return Object.keys(routes).reduce(
      (acc, route) => ({
        ...acc,
        ...Object.fromEntries(
          Object.entries(shortcuts)
            // TODO: Handle this better, filtering out as a quickfix.
            .filter(
              ([path]) =>
                path !== '/recalls/draft' ||
                auth?.role?.permissions?.includes('recall:create'),
            )
            .filter(([path]) => matchPath(path, route)),
        ),
      }),
      {},
    );
  }, [auth, shortcuts]);

  return (
    <div className="app-header-menu d-flex">
      {Object.entries(list).map(([path, { label }]) => (
        <Button
          key={path}
          variant="outlined"
          size="medium"
          color="inherit"
          component={Link}
          className={`${classes.link} font-size-xs mx-2`}
          to={path}
        >
          {label.includes('Add') && <AddIcon />}
          {path.endsWith('update') && <EditIcon />}
          {label}
        </Button>
      ))}
    </div>
  );
};

export default HeaderMenu;
