import Resource from '../Resource';

class Staff extends Resource {
  static path = '/staff';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  removeRole = Resource.method({
    method: 'DELETE',
    path: '/:id/role/:roleId',
  });

  changeDefaultRole = Resource.method({
    method: 'PATCH',
    path: '/:id/role/:roleId',
  });

  addRole = Resource.method({
    method: 'POST',
    path: '/:id/role',
  });

  resendInvite = Resource.method({
    method: 'POST',
    path: '/:id/invitation/resend',
  });

  addEmailByCredentials = Resource.method({
    method: 'POST',
    path: '/email',
  });

  updateEmail = Resource.method({
    method: 'PATCH',
    path: '/:id/email',
  });

  activate = Resource.method({
    method: 'PATCH',
    path: '/:id/activate',
  });

  setPasswordWithoutCurrent = Resource.method({
    method: 'POST',
    path: '/:id/password',
  });

  recoverStaticPassword = Resource.method({
    method: 'GET',
    path: '/:id/password',
  });

  export = Resource.method({
    method: 'GET',
    path: 'report.xlsx',
    responseType: 'stream',
  });
}

export default Staff;
