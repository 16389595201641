import Resource from '../Resource';

class Token extends Resource {
  static path = '/token';
  static include = [Resource.CREATE];

  switch = Resource.method({
    method: 'POST',
    path: '/switch/:roleId?',
  });

  administer = Resource.method({
    method: 'POST',
    path: '/switch/company/:companyId',
  });

  impersonate = Resource.method({
    method: 'POST',
    path: '/switch/staff/:staffId',
  });
}

export default Token;
