import Resource from '../Resource';

class StoreGroup extends Resource {
  static path = '/store-group';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  addStore = Resource.method({
    method: 'POST',
    path: '/:id/store',
  });

  removeStore = Resource.method({
    method: 'DELETE',
    path: '/:id/store/:storeId',
  });

  staff = Resource.method({
    method: 'GET',
    path: '/:id/staff',
  });
}

export default StoreGroup;
