import React, { Fragment, useContext } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Hidden, IconButton, AppBar, Box, Tooltip } from '@material-ui/core';

import { ErrorOutline as WarningIcon } from '@material-ui/icons';

import projectLogo from 'src/assets/images/logo-w.png';

import HeaderLogo from '../HeaderLogo';
import HeaderUserbox from '../HeaderUserbox';
import HeaderSearch from '../HeaderSearch';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

import ThemeConfigContext from 'src/components/ThemeConfigProvider/context';
import HeaderMenu from '../HeaderMenu';

import useAuth from 'src/hooks/useAuth';

const Header = (props) => {
  const {
    setThemeOption,
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    sidebarToggle,
  } = useContext(ThemeConfigContext);

  const { role } = useAuth();

  const toggleSidebar = () => {
    setThemeOption('sidebarToggle', !sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setThemeOption('sidebarToggleMobile', !sidebarToggleMobile);
  };

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': props.isCollapsedLayout,
          'bg-sunrise-purple': role?.isPseudoRole,
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}
      >
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box className="app-logo-wrapper" title="Recalls 123">
              <Link to="/" className="app-logo-link">
                <i className="bg-transparent">
                  <img
                    className="app-logo-img"
                    alt="Recalls 123"
                    src={projectLogo}
                  />
                </i>
              </Link>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle,
                  })}
                >
                  <Tooltip title="Toggle Sidebar" placement="right">
                    <IconButton
                      color="inherit"
                      onClick={toggleSidebar}
                      size="medium"
                      className="btn-inverse"
                    >
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <HeaderSearch />
              <HeaderMenu />
            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            {role?.isPseudoRole && (
              <span className="font-weight-bolder font-size-md  animate-pulse">
                <WarningIcon className="mb-1" />{' '}
                <span className="d-none d-sm-block">IMPERSONATING AS</span>
              </span>
            )}
            <HeaderUserbox />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium"
                >
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

export default Header;
