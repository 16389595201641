import React from 'react';

export const PrinterSetupIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    enableBackground="new 0 0 48 48"
    xmlSpace="preserve"
  >
    <path
      fill="#616161"
      d="M4,27h40V17c0-2.209-1.791-4-4-4H8c-2.209,0-4,1.791-4,4V27z"
    />
    <g>
      <rect x="9" y="10" fill="#424242" width="30" height="3" />
      <path
        fill="#424242"
        d="M8,39h32c2.209,0,4-1.791,4-4v-8H4v8C4,37.209,5.791,39,8,39"
      />
    </g>
    <path
      fill="#242424"
      d="M37.5,34h-27C9.672,34,9,33.328,9,32.5S9.672,31,10.5,31h27c0.828,0,1.5,0.672,1.5,1.5S38.328,34,37.5,34"
    />
    <g>
      <rect x="11" y="5" fill="#90CAF9" width="26" height="8" />
      <rect x="11" y="34" fill="#90CAF9" width="26" height="9" />
    </g>
    <rect x="11" y="32" fill="#42A5F5" width="26" height="2" />
    <g>
      <path
        fill="#18FFFF"
        d="M28.386,21.984l2.597-2.138l-1.387-2.452l-3.449,1.064L25.437,15h-2.873l-0.714,3.481l-3.48-1.046
L17,19.898l2.614,2.119l-2.598,2.137l1.388,2.452l3.449-1.063L22.563,29h2.873l0.714-3.48l3.48,1.046L31,24.104L28.386,21.984z
M24,25.5c-1.983,0-3.592-1.566-3.592-3.5c0-1.933,1.608-3.498,3.592-3.498s3.592,1.565,3.592,3.498
C27.592,23.934,25.983,25.5,24,25.5z"
      />
      <path
        fill="#18FFFF"
        d="M24,17c-2.762,0-5,2.238-5,5s2.238,5,5,5s5-2.238,5-5S26.762,17,24,17z M24,25c-1.657,0-3-1.343-3-3
c0-1.658,1.343-3,3-3s3,1.342,3,3C27,23.657,25.657,25,24,25z"
      />
    </g>
  </svg>
);

export const PrinterErrorIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    enableBackground="new 0 0 48 48"
    xmlSpace="preserve"
  >
    <path
      fill="#616161"
      d="M4,27h40V17c0-2.209-1.791-4-4-4H8c-2.209,0-4,1.791-4,4V27z"
    />
    <g>
      <rect x="9" y="10" fill="#424242" width="30" height="3" />
      <path
        fill="#424242"
        d="M8,39h32c2.209,0,4-1.791,4-4v-8H4v8C4,37.209,5.791,39,8,39"
      />
    </g>
    <path
      fill="#242424"
      d="M37.5,34h-27C9.672,34,9,33.328,9,32.5S9.672,31,10.5,31h27c0.828,0,1.5,0.672,1.5,1.5S38.328,34,37.5,34"
    />
    <g>
      <rect x="11" y="5" fill="#90CAF9" width="26" height="8" />
      <rect x="11" y="34" fill="#90CAF9" width="26" height="9" />
    </g>
    <rect x="11" y="32" fill="#42A5F5" width="26" height="2" />
    <circle fill="#FFD600" cx="24" cy="22" r="7" />
    <g>
      <path
        fill="#934E09"
        d="M22.703,26.26c0-0.176,0.034-0.341,0.095-0.493c0.066-0.153,0.152-0.285,0.268-0.397
 c0.116-0.114,0.258-0.201,0.42-0.26c0.164-0.066,0.343-0.096,0.55-0.096c0.201,0,0.387,0.029,0.551,0.096
 c0.165,0.059,0.307,0.146,0.423,0.26c0.115,0.112,0.201,0.244,0.268,0.397c0.061,0.152,0.095,0.317,0.095,0.493
 c0,0.183-0.034,0.348-0.095,0.493c-0.066,0.149-0.152,0.279-0.268,0.396c-0.116,0.107-0.258,0.193-0.423,0.258
 c-0.164,0.061-0.35,0.094-0.551,0.094c-0.207,0-0.386-0.033-0.55-0.094c-0.162-0.064-0.304-0.15-0.42-0.258
 c-0.115-0.116-0.201-0.246-0.268-0.396C22.737,26.607,22.703,26.442,22.703,26.26"
      />
      <g>
        <polygon
          fill="#934E09"
          points="24.99,24 23.008,24 22.728,17 25.271,17 		"
        />
      </g>
    </g>
  </svg>
);
