import React, { Fragment, useContext, useMemo } from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';

import { PrinterSetupButton } from 'src/components/PrinterSetup';

import SidebarHeader from '../SidebarHeader';
import SidebarMenu from '../SidebarMenu';
import SidebarBox from '../SidebarBox';

import ThemeConfigContext from 'src/components/ThemeConfigProvider/context';

import { makeStyles } from '@material-ui/styles';

import useAuth from 'src/hooks/useAuth';
import { navByPermissions } from 'src/routes';

const useStyles = makeStyles((theme) => ({
  printerSetupButton: {
    position: 'fixed',
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    width: 60,
    height: 60,
    zIndex: 100,
  },
}));

const Sidebar = (props) => {
  const {
    setThemeOption,
    sidebarToggleMobile,
    sidebarFixed,
    sidebarHover,
    sidebarToggle,
  } = useContext(ThemeConfigContext);

  const classes = useStyles();

  const auth = useAuth();
  const { role } = auth;

  const navItems = useMemo(() => navByPermissions(auth), [auth]);

  const toggleHoverOn = () => setThemeOption('sidebarHover', true);
  const toggleHoverOff = () => setThemeOption('sidebarHover', false);

  const closeDrawer = () =>
    setThemeOption('sidebarToggleMobile', !sidebarToggleMobile);

  const sidebarMenuContent = (
    <div
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover,
      })}
    >
      {navItems.map((list) => (
        <SidebarMenu
          component="div"
          key={list.label}
          pages={list.content}
          title={list.label}
        />
      ))}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg"
        >
          <SidebarHeader />
          <PerfectScrollbar>
            {role?.resource && <SidebarBox auth={auth} />}
            {sidebarMenuContent}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed,
          })}
          square
          open={sidebarToggle}
          elevation={2}
        >
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover,
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {role?.resource && <SidebarBox auth={auth} />}
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
          <PrinterSetupButton className={classes.printerSetupButton} />
        </Paper>
      </Hidden>
    </Fragment>
  );
};

export default Sidebar;
