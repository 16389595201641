import { lazy } from 'react';

const Dashboard = lazy(() => import('src/pages/Dashboard'));
const Account = lazy(() => import('src/pages/Account'));
const EditCompany = lazy(() => import('src/pages/EditCompany'));
const NewCompany = lazy(() => import('src/pages/NewCompany'));
const Companies = lazy(() => import('src/pages/Companies'));
const Divisions = lazy(() => import('src/pages/Divisions'));
const ImportDivisions = lazy(() => import('src/pages/ImportDivisions'));
const DivisionView = lazy(() => import('src/pages/DivisionView'));
const DivisionGroups = lazy(() => import('src/pages/DivisionGroups'));
const DivisionGroupView = lazy(() => import('src/pages/DivisionGroupView'));
const EditDivision = lazy(() => import('src/pages/EditDivision'));
const Stores = lazy(() => import('src/pages/Stores'));
const StoreView = lazy(() => import('src/pages/StoreView'));
const NewStore = lazy(() => import('src/pages/NewStore'));
const ImportStores = lazy(() => import('src/pages/ImportStores'));
const EditStore = lazy(() => import('src/pages/EditStore'));
const StoreGroups = lazy(() => import('src/pages/StoreGroups'));
const ImportStoreGroups = lazy(() => import('src/pages/ImportStoreGroups'));
const StoreGroupView = lazy(() => import('src/pages/StoreGroupView'));
const Staff = lazy(() => import('src/pages/Staff'));
const NewStaff = lazy(() => import('src/pages/NewStaff'));
const ImportStaff = lazy(() => import('src/pages/ImportStaff'));
const StaffView = lazy(() => import('src/pages/StaffView'));
const Admin = lazy(() => import('src/pages/Admin'));
const NewAdmin = lazy(() => import('src/pages/NewAdmin'));
const AdminView = lazy(() => import('src/pages/AdminView'));
const EditAdmin = lazy(() => import('src/pages/EditAdmin'));
const Roles = lazy(() => import('src/pages/Roles'));
const RoleView = lazy(() => import('src/pages/RoleView'));
const Configurations = lazy(() => import('src/pages/Configurations'));
const NewRole = lazy(() => import('src/pages/NewRole'));
const Recalls = lazy(() => import('src/pages/Recalls'));
const DraftRecall = lazy(() => import('src/pages/DraftRecall'));
const RecallView = lazy(() => import('src/pages/RecallView'));
const RecallResponderView = lazy(() => import('src/pages/RecallResponderView'));
const NewRecallResponse = lazy(() => import('src/pages/NewRecallResponse'));
const ReportingView = lazy(() => import('src/pages/ReportingView'));
const ReportingDetailView = lazy(() => import('src/pages/ReportingDetailView'));
const MessagingGroup = lazy(() => import('src/pages/MessagingGroup'));
const MessagingGroupView = lazy(() => import('src/pages/MessagingGroupView'));
const NotificationTemplates = lazy(() =>
  import('src/pages/NotificationTemplates'),
);
const Automation = lazy(() => import('src/pages/Automation'));
const AutomatedTask = lazy(() => import('src/pages/AutomatedTask'));
const Activities = lazy(() => import('src/pages/Activities'));
const NewRecallResponseRevision = lazy(() =>
  import('src/pages/NewRecallResponseRevision'),
);
const EditStaff = lazy(() => import('src/pages/EditStaff'));
const TestEmailDelivery = lazy(() => import('src/pages/TestEmailDelivery'));
const KnowledgeCenter = lazy(() => import('src/pages/KnowledgeCenter'));
const Bills = lazy(() => import('src/pages/Bills'));
const BillingPolicies = lazy(() => import('src/pages/BillingPolicies'));
const NewBillingPolicy = lazy(() => import('src/pages/NewBillingPolicy'));
const EditBillingPolicy = lazy(() => import('src/pages/EditBillingPolicy'));

const protectedRoutes = {
  '/dashboard': { component: Dashboard },
  '/account': { component: Account },
  '/staff/:id/update': { component: EditStaff },
  '/company/update': {
    component: EditCompany,
    permission: 'company:update',
  },
  '/company': {
    component: Companies,
    permission: 'company:list',
    menu: { label: 'Companies' },
  },
  '/company/new': {
    component: NewCompany,
    permission: 'company:create',
  },
  '/divisions': {
    component: Divisions,
    permission: 'division:list',
    menu: { label: 'Divisions' },
  },
  '/divisions/import': {
    component: ImportDivisions,
    permission: 'division:create',
    check: ({ user }) => user.isSuper,
  },
  '/divisions/:id': {
    component: DivisionView,
    permission: 'division:view',
  },
  '/divisions/:id/update': {
    component: EditDivision,
    permission: 'division:update',
  },
  '/division-groups': {
    component: DivisionGroups,
    permission: 'division-group:list',
    menu: { label: 'Division Groups' },
  },
  '/division-groups/:id': {
    component: DivisionGroupView,
    permission: 'division-group:view',
  },
  '/stores': {
    component: Stores,
    permission: 'store:list',
    menu: { label: 'Stores' },
  },
  '/stores/new': {
    component: NewStore,
    permission: 'store:create',
  },
  '/stores/import': {
    component: ImportStores,
    permission: 'store:create',
    check: ({ user }) => user.isSuper,
  },
  '/stores/:id': {
    component: StoreView,
    permission: 'store:view',
  },
  '/stores/:id/update': {
    component: EditStore,
    permission: 'store:update',
  },
  '/store-groups': {
    component: StoreGroups,
    permission: 'store-group:list',
    menu: { label: 'Store Groups' },
  },
  '/store-groups/import': {
    component: ImportStoreGroups,
    permission: 'store-group:create',
    check: ({ user }) => user.isSuper,
  },
  '/store-groups/:id': {
    component: StoreGroupView,
    permission: 'store-group:view',
  },
  '/messaging-groups': {
    component: MessagingGroup,
    permission: 'messaging-group:list',
    menu: { label: 'Messaging Groups' },
  },
  '/messaging-groups/:id': {
    component: MessagingGroupView,
    permission: 'messaging-group:list',
  },
  '/staff': {
    component: Staff,
    permission: 'staff:list',
    menu: { label: 'Users' },
  },
  '/staff/new': {
    component: NewStaff,
    permission: 'staff:create',
  },
  '/staff/import': {
    component: ImportStaff,
    permission: 'staff:create',
    check: ({ user }) => user.isSuper,
  },
  '/staff/:id': {
    component: StaffView,
    permission: 'staff:view',
  },
  '/recalls': {
    component: Recalls,
    permission: 'recall:list',
    menu: { label: 'My Recalls', priority: 10 },
  },
  '/recalls/draft/:id?': {
    component: DraftRecall,
    permission: 'recall:create',
    exact: false,
  },
  '/recalls/:id': [
    {
      component: RecallView,
      permission: 'recall:view',
    },
    {
      component: RecallResponderView,
      permission: 'recall:list',
    },
  ],
  '/recalls/:recallId/response/:responseId/revise': {
    component: NewRecallResponseRevision,
    permission: 'recall-response:create',
  },
  '/recalls/:recallId/response/:responseId?': {
    component: NewRecallResponse,
    permission: 'recall-response:create',
    check: ({ role }) => role.resource?.type === 'Store',
  },
  '/recalls/:recallId/store/:storeId/response/:responseId?': {
    component: NewRecallResponse,
    permission: 'recall-response:create',
    check: ({ role }) => role.resource?.type !== 'Store',
  },
  '/admin': {
    component: Admin,
    permission: 'admin:list',
    menu: { label: 'Admin Users' },
  },
  '/bills': {
    component: Bills,
    permission: 'bill:list',
    menu: { label: 'Bills', group: 'Billing' },
  },
  '/billing-policy': {
    component: BillingPolicies,
    permission: 'billing-policy:list',
    menu: { label: 'Policies', group: 'Billing' },
  },
  '/billing-policy/new': {
    component: NewBillingPolicy,
    permission: 'billing-policy:create',
  },
  '/billing-policy/:id/update': {
    component: EditBillingPolicy,
    permission: 'billing-policy:update',
  },
  '/admin/new': {
    component: NewAdmin,
    permission: 'admin:create',
  },
  '/admin/:id': {
    component: AdminView,
    permission: 'admin:view',
  },
  '/admin/:id/update': {
    component: EditAdmin,
    permission: 'admin:update',
  },
  '/roles': {
    component: Roles,
    permission: 'role:list',
    check: ({ user, role }) =>
      user.isSuper && role.resource?.type !== 'Company',
    menu: { label: 'Roles' },
  },
  '/roles/new': {
    component: NewRole,
    permission: 'role:create',
  },
  '/roles/:id': {
    component: RoleView,
    permission: 'role:view',
  },
  '/configurations': {
    component: Configurations,
    permission: 'configuration:list',
    check: ({ user, role }) =>
      user.isSuper && role.resource?.type !== 'Company',
    menu: { label: 'Configurations' },
  },
  '/reports': {
    component: ReportingView,
    permission: 'recall:list',
    menu: { label: 'Reports' },
  },
  '/reports/:report': {
    component: ReportingDetailView,
    permission: 'recall:list',
  },
  '/activity-logs': {
    component: Activities,
    permission: 'activity:list',
    menu: { label: 'Activity Logs' },
  },
  '/notification-templates': {
    component: NotificationTemplates,
    permission: 'notification-template:list',
    menu: { label: 'Notification Templates', group: 'Advanced' },
  },
  '/notification-templates/:template': {
    component: NotificationTemplates,
    permission: 'notification-template:list',
    exact: false,
  },
  '/automations': {
    component: Automation,
    permission: 'automation:list',
    menu: { label: 'Automation', group: 'Advanced' },
  },
  '/automations/:id': {
    component: AutomatedTask,
    permission: 'automation:view',
  },
  '/test-email-delivery': {
    component: TestEmailDelivery,
    permission: 'diagnostic-test:list',
    menu: { label: 'Test Email Delivery', group: 'Diagnostics' },
  },
  '/knowledge-center': {
    component: KnowledgeCenter,
    menu: { label: 'Knowledge Center' },
  },
};

export default protectedRoutes;
