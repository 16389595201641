import React, { Fragment, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import {
  Grid,
  Fab,
  Container,
  InputAdornment,
  Drawer,
  IconButton,
  List,
  ListItem,
  TextField,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

import api from 'src/services/api';
let searchTimer;

const HeaderSearch = () => {
  const searchRef = useRef(null);

  const [results, setResults] = useState({ data: [], total: 0 });

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchValue(query);

    if (query.length < 3) return;
    if (searchTimer) clearTimeout(searchTimer);
    searchTimer = setTimeout(() => search(query), 300);
  };

  const search = async (query) => {
    // TODO: avoid unwanted calls.
    const [stores, staff, recalls] = (
      await Promise.allSettled([
        api.store.list({ search: query }),
        api.staff.list({ search: query }),
        api.recall.list({ search: query }),
      ])
    ).map(({ value }) => value);

    const { total, data } = Object.entries({ stores, staff, recalls }).reduce(
      ({ total, data }, [group, item]) => ({
        total: total + (item?.totalCount || 0),
        data: { ...data, [group]: item?.data || [] },
      }),
      {
        total: 0,
        data: {},
      },
    );

    setResults({
      data,
      total,
    });

    if (query) {
      if (!openSearchResults) {
        setOpenSearchResults(true);
      }
    } else {
      setOpenSearchResults(false);
    }
  };

  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [state, setState] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(!state);
  };

  return (
    <Fragment>
      <IconButton
        size="medium"
        onClick={toggleDrawer(true)}
        color="inherit"
        className="btn-inverse font-size-xs"
      >
        <SearchIcon />
      </IconButton>
      <Drawer anchor="top" open={state} onClose={toggleDrawer(false)}>
        <Container maxWidth="lg" className="py-4">
          <div className="d-flex justify-content-between">
            <div className="text-black">
              <h1 className="display-3 mb-2 font-weight-bold">Search</h1>
              <p className="font-size-lg text-black-50">
                Please use the field below to search something.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Fab onClick={toggleDrawer(false)} size="medium" color="primary">
                <CloseTwoToneIcon />
              </Fab>
            </div>
          </div>
        </Container>
        <div className="app-search-wrapper" ref={searchRef}>
          <Container maxWidth="lg">
            <TextField
              className="app-search-input"
              fullWidth
              value={searchValue}
              onChange={handleSearchChange}
              inputProps={{ 'aria-label': 'search' }}
              label="Search…"
              autoFocus
              placeholder="Please type in at least three characters."
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="app-search-icon" />
                  </InputAdornment>
                ),
              }}
            />
          </Container>
        </div>
        <Container maxWidth="lg" className="pb-3">
          <div
            className={clsx('no-search-results', {
              'search-results-hidden': results.total,
            })}
          >
            <div>
              <div className="text-warning font-weight-bold font-size-xl">
                No search results!
              </div>
              <p className="mb-0 font-size-lg text-black-50">
                Use the field above to begin searching for something
              </p>
            </div>
          </div>
          <div
            className={clsx('search-results', {
              'search-results-hidden': !results.total,
            })}
          >
            <Grid container spacing={3} className="py-4">
              {Object.entries(results.data)
                .filter(([, values]) => values.length)
                .map(([group, values]) => (
                  <Grid sm={4} item key={group}>
                    <Typography variant="h6" className="text-capitalize">
                      {group}
                    </Typography>
                    <List>
                      {values.map((item) => (
                        <ListItem
                          className="rounded-sm"
                          button
                          key={item._id}
                          component={Link}
                          to={`/${group}/${item._id}`}
                          onClick={toggleDrawer(false)}
                        >
                          <ListItemIcon className="pr-3 min-w-auto">
                            <SearchIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.firstName || item.name || item.title}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                ))}
            </Grid>
          </div>
        </Container>
      </Drawer>
    </Fragment>
  );
};

export default HeaderSearch;
