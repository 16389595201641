import Resource from '../Resource';

class billingPolicy extends Resource {
  static path = '/billing-policy';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  getUpcomingBill = Resource.method({
    method: 'GET',
    path: '/:id/bill/upcoming',
  });
}

export default billingPolicy;
