import Resource from '../Resource';

class Recall extends Resource {
  static path = '/recall';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  nextSequence = Resource.method({
    method: 'GET',
    path: '/next',
  });

  export = Resource.method({
    method: 'GET',
    path: 'report.xlsx',
    responseType: 'stream',
  });

  updateAttachments = Resource.method({
    method: 'PUT',
    path: '/:id/attachment',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  uploadFile = Resource.method({
    method: 'PUT',
    path: '/:id/file',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  addItem = Resource.method({
    method: 'POST',
    path: '/:id/item',
  });

  updateItem = Resource.method({
    method: 'PUT',
    path: '/:id/item/:itemId',
  });

  removeItem = Resource.method({
    method: 'DELETE',
    path: '/:id/item/:itemId',
  });

  publish = Resource.method({
    method: 'POST',
    path: '/:id/publish',
  });

  addTargets = Resource.method({
    method: 'POST',
    path: '/:id/target',
  });

  removeTarget = Resource.method({
    method: 'DELETE',
    path: '/:id/target',
  });

  end = Resource.method({
    method: 'POST',
    path: '/:id/end',
  });

  responses = Resource.method({
    method: 'GET',
    path: '/:id/response',
  });

  clone = Resource.method({
    method: 'POST',
    path: '/:id/clone',
  });

  delete = Resource.method({
    method: 'DELETE',
    path: '/:id',
  });

  importTarget = Resource.method({
    method: 'PUT',
    path: '/:id/target/import',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  validateTargetImport = Resource.method({
    method: 'PUT',
    path: '/:id/target/import?preview=true',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  overrides = Resource.method({
    method: 'GET',
    path: '/:id/override',
  });

  exportItems = Resource.method({
    method: 'GET',
    path: '/:id/items.xlsx',
    responseType: 'stream',
  });

  createRevision = Resource.method({
    method: 'POST',
    path: '/:id/revision',
  });

  allItems = Resource.method({
    method: 'GET',
    path: '/item',
  });

  divisions = Resource.method({
    method: 'GET',
    path: '/:id/division',
  });

  feePolicyReport = Resource.method({
    method: 'GET',
    path: '/fee-policy',
  });

  exportFeePolicyReport = Resource.method({
    method: 'GET',
    path: '/fee-policy.xlsx',
    responseType: 'stream',
  });

  getProductionEndDate = Resource.method({
    method: 'GET',
    path: '/production-end-date',
  });

  getTargetsCount = Resource.method({
    method: 'GET',
    path: '/:id/targets/count',
  });
}

export default Recall;
