import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 540,
    margin: '0 auto',
  },
  title: {
    fontWeight: 300,
    color: '#e73737',
    fontSize: 30,
    lineHeight: '41px',
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 12,
  },
  subtitle: {
    fontSize: 16,
    textAlign: 'center',
    color: '#596377',
    lineHeight: '26px',
  },
  actions: {
    display: 'flex',
    marginTop: 36,
    '&> * ': {
      marginLeft: 8,
      marginRight: 8,
    },
  },
}));

const getButtonProps = (action) => {
  const buttonProps = {
    color: 'primary',
    variant: 'contained',
    onClick: action.onAction,
  };

  if (action.url) {
    buttonProps.component = Link;
    buttonProps.to = action.url;
  }

  return buttonProps;
};

const ErrorState = ({ title, subtitle, action, className }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.title}>{title || 'Oops!'}</div>
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      {action && (
        <div className={classes.actions}>
          <Button {...getButtonProps(action)}>{action.content}</Button>
        </div>
      )}
    </div>
  );
};

const ActionType = PropTypes.shape({
  content: PropTypes.string,
  url: PropTypes.string,
  onAction: PropTypes.func,
});

ErrorState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: ActionType,
};

export default ErrorState;
