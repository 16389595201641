import React from 'react';
import LoadingState from 'src/components/LoadingState';

const SuspenseLoading = ({ noSubtitle, subtitle }) => {
  return (
    <>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center p-3">
        <div className="d-flex align-items-center flex-column px-4">
          <LoadingState />
        </div>
        {!noSubtitle && (
          <div className="text-muted font-size-xl text-center pt-2">
            {subtitle || 'Please wait while we load the page..'}
          </div>
        )}
      </div>
    </>
  );
};

export default SuspenseLoading;
