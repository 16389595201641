import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Sidebar, Header, Footer } from './components';

import ThemeConfigContext from 'src/components/ThemeConfigProvider/context';

const LayoutWithSidebar = (props) => {
  const { children } = props;

  const {
    sidebarToggle,
    sidebarFixed,
    footerFixed,
    contentBackground,
  } = useContext(ThemeConfigContext);

  return (
    <Fragment>
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header />
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed,
          })}
        >
          <Sidebar />
          <div
            className={clsx('app-content', {
              'app-content-sidebar-collapsed': sidebarToggle,
              'app-content-sidebar-fixed': sidebarFixed,
              'app-content-footer-fixed': footerFixed,
            })}
          >
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LayoutWithSidebar.propTypes = {
  children: PropTypes.node,
};

export default LayoutWithSidebar;
