import Resource from '../Resource';

class Integration extends Resource {
  static path = '/integration';

  webscanAuth = Resource.method({
    method: 'POST',
    path: '/webscan/auth',
  });
}

export default Integration;
