import Resource from '../Resource';

class DivisionGroup extends Resource {
  static path = '/division-group';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  addDivision = Resource.method({
    method: 'POST',
    path: '/:id/division',
  });

  removeDivision = Resource.method({
    method: 'DELETE',
    path: '/:id/division/:divisionId',
  });

  staff = Resource.method({
    method: 'GET',
    path: '/:id/staff',
  });
}

export default DivisionGroup;
