import { DateTime as LuxonDateTime } from 'luxon';

export { CustomLuxonUtils as DateUtils } from './date-utils';
export * from 'luxon';

export class DateTime extends LuxonDateTime {
  static DATETIME_WITH_ZONE = {
    ...LuxonDateTime.DATE_MED,
    ...LuxonDateTime.TIME_SIMPLE,
    timeZoneName: 'short',
    day: '2-digit',
  };

  static DATE_WITH_ZONE = {
    ...LuxonDateTime.DATE_MED,
    timeZoneName: 'short',
    day: '2-digit',
  };
}
