import Resource from '../Resource';

class Automation extends Resource {
  static path = '/automation';
  static include = [
    Resource.CREATE,
    Resource.GET,
    Resource.UPDATE,
    Resource.LIST,
    Resource.DELETE,
  ];

  enums = Resource.method({
    method: 'GET',
    path: '/enums',
  });

  tasks = Resource.method({
    method: 'GET',
    path: '/:id/tasks',
  });
}

export default Automation;
