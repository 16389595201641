import Resource from '../Resource';

class MessagingGroup extends Resource {
  static path = '/messaging-group';
  static include = [
    Resource.CREATE,
    Resource.UPDATE,
    Resource.LIST,
    Resource.GET,
  ];

  removeTarget = Resource.method({
    method: 'DELETE',
    path: '/:id/target/:targetId',
  });

  addTarget = Resource.method({
    method: 'POST',
    path: '/:id/target',
  });

  listTargets = Resource.method({
    method: 'GET',
    path: '/:id/target',
  });

  updateTarget = Resource.method({
    method: 'PATCH',
    path: '/:id/target/:targetId',
  });

  exportTargets = Resource.method({
    method: 'GET',
    path: '/:id/targets.xlsx',
    responseType: 'stream',
  });
}

export default MessagingGroup;
