import Resource from '../Resource';

class RecallResponseRevision extends Resource {
  static path = '/recall/';

  list = Resource.method({
    method: 'GET',
    path: '/:id/revision',
  });

  create = Resource.method({
    method: 'POST',
    path: '/response/:responseId/revision',
  });

  accept = Resource.method({
    method: 'PATCH',
    path: '/revision/:id/accept',
  });

  reject = Resource.method({
    method: 'PATCH',
    path: '/revision/:id/reject',
  });

  label = Resource.method({
    method: 'GET',
    path: '/revision/:id/box/:boxId/label',
  });
}

export default RecallResponseRevision;
