import Resource from '../Resource';

class Company extends Resource {
  static path = '/company';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  enableValueAddedServices = Resource.method({
    method: 'POST',
    path: '/:id/vas',
  });

  disableValueAddedServices = Resource.method({
    method: 'DELETE',
    path: '/:id/vas',
  });

  addIntegration = Resource.method({
    method: 'POST',
    path: '/:id/integration',
  });

  removeIntegration = Resource.method({
    method: 'DELETE',
    path: '/:id/integration/:integrationId',
  });
}

export default Company;
