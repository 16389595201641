import { useRef, useEffect } from 'react';

const useIsFirstRender = () => {
  const renderCheckRef = useRef(true);
  useEffect(() => {
    renderCheckRef.current = false;
  }, []);
  return renderCheckRef.current;
};

export default useIsFirstRender;
