import Resource from '../Resource';

class Verification extends Resource {
  static path = '/verification';

  confirm = Resource.method({
    method: 'POST',
    path: '/:token',
  });

  resend = Resource.method({
    method: 'POST',
    path: '/resend',
  });
}

export default Verification;
