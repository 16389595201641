import React, { useEffect, useState } from 'react';
import Script from 'react-load-script';
import axios from 'axios';
import { Typography, Link } from '@material-ui/core';

const StatuspageWidget = () => {
  const [status, setStatus] = useState(null);
  useEffect(() => {
    axios
      .get('https://recalls123.statuspage.io/api/v2/status.json')
      .then(async (response) => setStatus(response.data))
      .catch(() => {});
  }, []);

  if (!status) return null;
  return (
    <>
      <Link target="_blank" rel="noopnener noreferrer" href={status.page.url}>
        <Typography variant="caption">
          Status:&nbsp;
          <span
            className={
              status.status.indicator !== 'none'
                ? status.status.indicator === 'critical'
                  ? 'text-danger'
                  : 'text-warning'
                : 'text-success'
            }
          >
            {status.status.description}
          </span>
        </Typography>
      </Link>
      <Script
        attributes={{ defer: true }}
        url={`${status.page.url}/embed/script.js`}
      />
    </>
  );
};

export default StatuspageWidget;
