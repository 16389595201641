import axios from 'axios';
import qs from 'querystring';
import path from './path';
import method from './ResourceMethod';
import { head } from 'ramda';

export const withProtocol = (url, protocol) => {
  return /^https?:\/\//i.test(url) ? url : `${protocol}://${url}`;
};

/**
 * Encapsulates request logic for an API Resource
 */
class Resource {
  static CREATE = 'create';
  static LIST = 'list';
  static GET = 'get';
  static UPDATE = 'update';
  static DELETE = 'delete';

  static method = method;

  constructor(api) {
    this.api = api;
    this.basePath = path.createPathInterpolator(
      this.constructor.hasOwnProperty('basePath')
        ? this.constructor.basePath
        : this.api.get('basePath'),
    );
    this.path = path.createPathInterpolator(this.constructor.path);
    this.host = this.constructor.host || api.get('host');
    this.timeout = this.constructor.timeout;

    if (this.constructor.include) {
      method.include.apply(this, this.constructor.include);
    }
  }

  createFullPath(commandPath, urlData) {
    return path.join(
      this.basePath(urlData),
      this.path(urlData),
      typeof commandPath == 'function' ? commandPath(urlData) : commandPath,
    );
  }

  /**
   * Creates a relative resource path with symbols left in (unlike
   * createFullPath which takes some data to replace them with). For example it
   * might produce: /users/:id
   */
  createResourcePathWithSymbols(pathWithSymbols = '') {
    return path.join('/', this.constructor.path, pathWithSymbols);
  }

  defaultRequestHeaders(version) {
    const headers = {
      Accept: 'application/json',
      // TODO: Only set if request has a body
      'Content-Type': 'application/json',
    };

    const token = this.api.get('token');

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return headers;
  }

  handleError = (error) => {
    let fallbackMessage = 'Something went wrong.';
    if (!error.response) {
      return Promise.reject(
        new Error('Network error! Please check your network and try again.'),
      );
    }
    if (error.response.status === 401) {
      this.api.emitter.emit('unauthorized');
      fallbackMessage = 'Your session has expired. Please login again.';
    }

    try {
      let { message } = error.response.data;
      if (
        error.response.data?.errors &&
        typeof error.response.data?.errors === 'object'
      ) {
        const errorDetail = head(Object.values(error.response.data?.errors));
        if (errorDetail) message = errorDetail;
      }
      // TODO: Make cleaner. Doing this to update error message.
      const err = new Error(message || fallbackMessage);
      err.response = error.response;
      return Promise.reject(err);
    } catch (err) {
      console.log(err);
      return Promise.reject(error);
    }
  };

  handleResponse = (response) => {
    Object.assign(response, {
      requestId: response.headers['request-id'],
    });

    return response;
  };

  async request({ method, path: requestPath, data, params, ...options }) {
    const axiosOptions = {};
    const http = axios.create(axiosOptions);

    http.interceptors.response.use(this.handleResponse, this.handleError);

    const headers = this.defaultRequestHeaders();

    if (options.headers) {
      Object.assign(headers, options.headers);
    }

    const host = options.host || this.host;
    const protocol = this.api.get('protocol');
    const timeout = options.timeout || this.timeout || this.api.get('timeout');
    const responseType = options.responseType || null;

    // axios is still passing json data for content type urlencoded
    // thus, converting to urlencoded format if content type is that.
    // https://github.com/axios/axios/issues/362
    if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      data = qs.stringify(data);
    }

    const payload = {
      url: path.join(withProtocol(host, protocol), requestPath),
      path: requestPath,
      method,
      headers,
      params,
      timeout,
      data,
      responseType,
    };

    if (options.transformResponse) {
      payload.transformResponse = options.transformResponse
        ? axios.defaults.transformResponse.concat(options.transformResponse)
        : null;
    }

    if (this.transformRequest) {
      payload.transformRequest = this.transformRequest;
    }

    // FIXME: Use axios stream when available.
    // Refer https://github.com/axios/axios/issues/479
    if (responseType === 'stream') {
      const query = qs.stringify(data);

      return fetch(`${payload.url}${query ? `?${query}` : ''}`, {
        method,
        headers,
      });
    }

    const response = await http.request(payload);

    if (response.status === 204) {
      response.data = {};
    }
    return response.data;
  }
}

export default Resource;
