import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

const info = {
  date: process.env.REACT_APP_DATE,
  version: process.env.REACT_APP_VERSION,
  commit: process.env.REACT_APP_COMMIT,
  build: process.env.REACT_APP_BUILD_NUM,
};

const app = (window.app = {});
Object.entries(info).forEach(([key, value]) => {
  if (value) app[key] = value;
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
