import React, { useState, useEffect, useCallback } from 'react';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import Dialog from 'src/components/Dialog';

import { GetApp as DownloadIcon } from '@material-ui/icons';
import { head } from 'ramda';
import qz from 'qz-tray';

import LoadingButton from 'src/components/LoadingButton';
import LoadingState from 'src/components/LoadingState';
import api from 'src/services/api';

const PrinterSetup = ({ onClose, skip }) => {
  const [error, setError] = useState('');
  const [status, setStatus] = useState('scanning');
  const [printers, setPrinters] = useState([]);
  const [downloadInfo, setDownloadInfo] = useState(null);
  const [configuration, setConfiguration] = useState({
    genericPrinter: '',
    labelPrinter: '',
    hasLabelPrinter: false,
    ...JSON.parse(window.localStorage.getItem('printing-preferences')),
  });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetch('https://api.github.com/repos/qzind/tray/releases', {
      cache: 'force-cache',
    })
      .then(async (response) => {
        const releases = await response.json();
        const latest = head(releases);

        if (!latest) return;

        let extension = '.exe';
        let os = 'Windows';
        if (navigator.platform.toLowerCase().includes('mac')) {
          extension = '.pkg';
          os = 'Apple OS X';
        } else if (navigator.platform.toLowerCase().includes('linux')) {
          extension = '.run';
          os = 'Linux / Unix';
        }
        const best = latest.assets.find(({ name }) => name.includes(extension));

        setDownloadInfo({
          url: best.browser_download_url,
          os,
          version: latest.tag_name,
          size: (best.size / (1024 * 1024)).toFixed(2),
        });
      })
      .catch(() => {});
  }, [setDownloadInfo]);

  const ConnectToQz = useCallback(async () => {
    if (qz.websocket.isActive()) return;

    qz.security.setCertificatePromise((resolve, reject) =>
      api.configuration
        .qzCertificate()
        .then(resolve)
        .catch(reject),
    );
    qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
    qz.security.setSignaturePromise((payload) => (resolve, reject) =>
      api.configuration
        .qzSign({ request: payload })
        .then(resolve)
        .catch(reject),
    );

    return qz.websocket.connect();
  }, []);

  const handleSetup = useCallback(async () => {
    setLoading(true);
    try {
      await ConnectToQz();

      const printersAvailable = await qz.printers.find().catch((error) => {
        setError(error.message);
        throw error;
      });
      setPrinters(printersAvailable);
      setStatus('configure');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [setPrinters, setStatus, setError, setLoading, ConnectToQz]);

  useEffect(() => {
    const preferences = JSON.parse(
      window.localStorage.getItem('printing-preferences'),
    );
    if (preferences?.status === 'ok') handleSetup();

    ConnectToQz()
      .then(() => handleSetup())
      .catch(() => {
        setStatus('intro');
      });
  }, [handleSetup, ConnectToQz]);

  const handleConfigChange = (key) => (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setConfiguration({ ...configuration, [key]: value });
  };

  const handleComplete = () => {
    const preferences = JSON.parse(
      window.localStorage.getItem('printing-preferences'),
    );
    window.localStorage.setItem(
      'printing-preferences',
      JSON.stringify({
        ...preferences,
        ...configuration,
        status: configuration.genericPrinter ? 'ok' : 'fail', // TODO: make more intelligent
      }),
    );
    handleClose();
  };

  const handleClose = () => {
    let preferences = JSON.parse(
      window.localStorage.getItem('printing-preferences'),
    );

    if (skip) {
      preferences = {
        ...preferences,
        status: 'skipped',
      };
      window.localStorage.setItem(
        'printing-preferences',
        JSON.stringify(preferences),
      );
    }

    const event = new CustomEvent('recalls-printer-configuration-closed', {
      detail: preferences,
    });
    window.dispatchEvent(event);
    onClose();
  };

  return (
    <Dialog
      aria-labelledby="item-list-dailog-close"
      maxWidth="sm"
      fullWidth
      open={true}
    >
      <DialogTitle id="item-list-dailog-title">
        Setup one-click printing.
      </DialogTitle>

      <DialogContent>
        {status === 'scanning' && (
          <LoadingState subtitle="Scanning for printer utility.." />
        )}

        {status === 'intro' && (
          <>
            <p>
              One-click printing helps you to configure and manage different
              type of printers and set default printers based on document type.
              Please download and install the QZ Tray utility to continue.
            </p>

            <div className="flex flex-column justify-content-center align-box-row">
              {downloadInfo && (
                <Button
                  variant="contained"
                  color="primary"
                  download
                  href={downloadInfo.url}
                >
                  <DownloadIcon className="mr-2" />
                  <p className="text-left m-0">
                    <span>QZ Tray {downloadInfo.version}</span>
                    <br />
                    <small>
                      {downloadInfo.os} ({downloadInfo.size})MB
                    </small>
                  </p>
                </Button>
              )}

              <p className="text-center">
                <a
                  href="https://qz.io/download/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Click here to download directly from qz.io
                </a>
              </p>
            </div>

            {error ? (
              <p className="text-danger text-center">
                {error}.{' '}
                {error.includes('blocked') ? (
                  <>
                    Please click continue agian and hit{' '}
                    <span className="font-weight-bold">allow</span> when
                    prompted
                  </>
                ) : (
                  <>
                    If you already installed the QZ Tray utility, please make
                    sure it's running and click{' '}
                    <span className="font-weight-bold">continue</span> again.
                  </>
                )}
              </p>
            ) : (
              <p>
                Click <span className="font-weight-bold">continue</span> once
                the QZ Tray utility is installed and running, to proceed with
                the configuration.
              </p>
            )}
          </>
        )}

        {status === 'configure' && (
          <>
            <TextField
              label="Default general purpose printer"
              select
              fullWidth
              variant="outlined"
              onChange={handleConfigChange('genericPrinter')}
              value={configuration.genericPrinter}
            >
              {printers.map((printer) => (
                <MenuItem key={printer} value={printer}>
                  {printer}
                </MenuItem>
              ))}
            </TextField>

            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleConfigChange('hasLabelPrinter')}
                  checked={configuration.hasLabelPrinter}
                />
              }
              label="Enable EPL Label Printing when available."
            />
            <br />

            {configuration.hasLabelPrinter && (
              <TextField
                label="Default label printer"
                select
                fullWidth
                variant="outlined"
                onChange={handleConfigChange('labelPrinter')}
                value={configuration.labelPrinter}
              >
                {printers.map((printer) => (
                  <MenuItem key={printer} value={printer}>
                    {printer}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <Typography variant="caption" className="mt-2">
              <strong>Note</strong>: If you do not find your printer, please
              make sure the printer is connected and is configured with your
              computer.
            </Typography>
          </>
        )}
      </DialogContent>

      <DialogActions className="flex justify-content-center">
        {status !== 'scanning' && (
          <Button variant="outlined" size="small" onClick={handleClose}>
            {skip ? 'Skip for now' : 'Cancel'}
          </Button>
        )}

        {status === 'intro' && (
          <LoadingButton
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleSetup}
            loading={isLoading}
          >
            Continue
          </LoadingButton>
        )}
        {status === 'configure' && (
          <LoadingButton
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleComplete}
          >
            Done
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PrinterSetup;
