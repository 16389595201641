import Resource from '../Resource';

class Role extends Resource {
  static path = '/role';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.GET,
    Resource.UPDATE,
  ];

  permissions = Resource.method({
    method: 'GET',
    path: '/permissions',
  });

  delete = Resource.method({
    method: 'DELETE',
    path: '/:id',
  });
}

export default Role;
