import Resource from '../Resource';

class FileImport extends Resource {
  static path = '/import';
  static timeout = 120000;

  division = Resource.method({
    method: 'POST',
    path: '/division',
  });

  storeGroup = Resource.method({
    method: 'POST',
    path: '/store-group',
  });

  store = Resource.method({
    method: 'POST',
    path: '/store',
  });

  staff = Resource.method({
    method: 'POST',
    path: '/staff',
  });

  fromIntegration = Resource.method({
    method: 'POST',
    path: '/store/integration',
  });

  addStore = Resource.method({
    method: 'POST',
    path: '/store-group/stores',
  });
}

export default FileImport;
