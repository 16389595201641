import Resource, { withProtocol } from '../Resource';
import path from '../path';

class NotificationTemplate extends Resource {
  static path = '/notification-template';

  get = Resource.method({
    method: 'GET',
    path: '/:name',
  });

  createOrUpdate = Resource.method({
    method: 'POST',
    path: '/:name',
  });

  deleteTemplate = Resource.method({
    method: 'DELETE',
    path: '/:name',
  });

  stylesPath = function() {
    return path.join(
      withProtocol(this.api.get('host'), this.api.get('protocol')),
      this.createFullPath('styles.css'),
    );
  };

  uploadPath = function() {
    return path.join(
      withProtocol(this.api.get('host'), this.api.get('protocol')),
      this.createFullPath('file?_t=1'),
    );
  };
}

export default NotificationTemplate;
