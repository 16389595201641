import React, { Fragment, useCallback } from 'react';
import { Redirect } from 'react-router';

import { Grid } from '@material-ui/core';

import LoadingState from 'src/components/LoadingState';

import useAPI from 'src/hooks/useAPI';
import ErrorState from 'src/components/ErrorState';

import useAuth from 'src/hooks/useAuth';

const EmailConfirmation = ({ match: { params } }) => {
  const { logout } = useAuth();
  const { data, error } = useAPI(
    useCallback((api) => api.verification.confirm(params.secret), [params]),
  );

  if (data) {
    logout();
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            message: 'Your email has been verified. Please login to continue.',
          },
        }}
      />
    );
  }

  return (
    <Fragment>
      <div className="app-wrapper bg-white">
        <div className="app-main ">
          <div className="hero-wrapper bg-composed-wrapper min-vh-100">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <Grid
                item
                lg={6}
                md={9}
                className="px-4 px-lg-0 mx-auto text-center text-black"
              >
                {error ? (
                  <ErrorState subtitle={error.message} />
                ) : (
                  <>
                    <LoadingState />
                    <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mt-4 mb-3 text-black-50">
                      Please wait while we activate your account.
                    </h3>
                  </>
                )}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmailConfirmation;
