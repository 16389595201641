import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Error404 from 'src/pages/Error404';
import MotionDiv from 'src/components/MotionDiv';
import ErrorBoundary from 'src/components/ErrorBoundary';

import useAuth from 'src/hooks/useAuth';

const ProtectedRoute = ({ animate, permission, ...routeProps }) => {
  const { isAuthenticated, role } = useAuth();
  const permissions = role?.permissions;

  if (!isAuthenticated) {
    window.localStorage.setItem('redirect', routeProps.location.pathname);
    return <Redirect to="/login" />;
  }

  if (!permission || (permissions && permissions.includes(permission))) {
    const Wrapper = animate ? MotionDiv : Fragment;
    return (
      <Wrapper>
        <ErrorBoundary>
          <Route {...routeProps} />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  return <Route path="*" component={Error404} status={404} />;
};

export default ProtectedRoute;
