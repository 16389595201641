import Resource from '../Resource';

class RecallResponse extends Resource {
  static path = '/recall/';

  get = Resource.method({
    method: 'GET',
    path: '/response/:id',
  });

  list = Resource.method({
    method: 'GET',
    path: '/response',
  });

  export = Resource.method({
    method: 'GET',
    path: '/response.xlsx',
    responseType: 'stream',
  });

  create = Resource.method({
    method: 'POST',
    path: '/:id/response',
  });

  createByStore = Resource.method({
    method: 'POST',
    path: '/:id/store/:storeId/response',
  });

  sign = Resource.method({
    method: 'PATCH',
    path: '/response/:id/sign',
  });

  void = Resource.method({
    method: 'PATCH',
    path: '/response/:id/void',
  });

  report = Resource.method({
    method: 'GET',
    path: '/response/:id/report',
  });

  label = Resource.method({
    method: 'GET',
    path: '/response/:id/box/:boxId/label',
  });

  delete = Resource.method({
    method: 'DELETE',
    path: '/response/:id',
  });

  clone = Resource.method({
    method: 'POST',
    path: '/response/:id/clone',
  });
}

export default RecallResponse;
