import React from 'react';
import { styled } from '@material-ui/styles';
import ActivityIndicator from 'src/components/ActivityIndicator';

const Root = styled('div')({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const LoadingState = ({ subtitle, ...props }) => {
  return (
    <Root>
      <ActivityIndicator size={64} thickness={2} {...props} />
      {subtitle && (
        <div className="text-muted font-size-xl text-center pt-2">
          {subtitle}
        </div>
      )}
    </Root>
  );
};

export default LoadingState;
