import Resource from '../Resource';

class RecallTarget extends Resource {
  static path = '/recall/';

  list = Resource.method({
    method: 'GET',
    path: '/target',
  });

  export = Resource.method({
    method: 'GET',
    path: '/target.xlsx',
    responseType: 'stream',
  });
}

export default RecallTarget;
