import Resource from '../Resource';

class Message extends Resource {
  static path = '/message';
  static include = [Resource.LIST];

  export = Resource.method({
    method: 'GET',
    path: '/report.xlsx',
    responseType: 'stream',
  });
}

export default Message;
